.footer{
	margin: 0;
	background-image: url(../assets/images/logo1.png);
	background-color: black;
	background-repeat: no-repeat;
	color: white;
	background-size: 126px;
	background-position-x: -10px;
	@include mobile{
		background-image: none;
	}
	@include tablet{
		background-size: 156px;
		background-position-x: -40px;
	}
	&__title{
		border-bottom: 1px solid $primary;
	}
	&__text{
		color: white;
		margin:15px auto;
	}
	&__icon{
		color: $primary;
		margin-right: 10px;
		font-size: 25px;
	}
	&__redes{
		color: $primary;
		font-size: 40px;
		margin: 15px;
	}
	&__address{
		margin-bottom: 10px;
	}
}