.nav {
	width: 100%;
	height: 90px;
	display: flex;
	flex-direction: row;
	justify-content:space-between;
	align-items: center;
	position: fixed;
	top: 0;
	left: 0;
	padding: 10px;
	border-bottom: 1px solid $primary;
	background-color: whitesmoke;
	transition: all ease-in-out 400ms;
	z-index: 1001;
	&__casco{
		z-index: 998 !important;
		overflow: hidden;
		transition: all ease-in-out 250ms;
	}
	&__scroll{
		background-color: rgba(255,255,255,0.2);
	}
	&__nombre{
		z-index: 1000;
		position: relative;
	}
	@include mobile{
		height: 55px;
	}
	&__logo{
		height: 75px;
		@include mobile{
			height: 40px;
		}
		&__container {
			height: 75px;
			display: flex;
			flex-direction: row;
			justify-content: start;
			align-items: center;
			position: relative;
			&:hover .nav__casco{
				transform: translateX(35px)
			}
		}
	}
	&__button{
		list-style:none;
		position: relative;
		overflow: hidden;
		display: inline-block;
		text-decoration: none;
		@include tablet {
			margin-bottom: 20px;
		}
		&::after{
			content: "";
			position: absolute;
			bottom: 0;
			left: -100%;
			width: 100%;
			height: 3px;
			background: $primary;
			transition: left .8s;
		}
		&:hover:after {
			left: 0;
		}
		&__container{
			width: 60%;
			display: flex;
			flex-direction: row;
			justify-content: space-around;
			align-items: center;
			@include tablet{
				position: absolute;
				height: 100vh;
				top: 0;
				margin: 0;
				right: 0;
				width: 18%;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				background-color: $primary ;
				font-size: 15px;
				padding: 0;
				transform: translateX(100%);
				transition: transform ease-in-out 300ms;
			}
			@include mobile{
				font-size: 15px;
				width: 30%;
			}
		}
	}
	&__menuToggle{
		font-size: 30px;
		margin-right: 10px;
		display: none;
		@include tablet {
			display: block;
			z-index: 10000;
			border-radius: 50%;
			width: 40px;
			height: 40px;
			padding:0;

		}
	}
}

.active{
	transform: translateX(0);	
	transition: transform ease-in-out 300ms;
}

// .test3{
// 	position: relative;
// }