@import "./mixins.scss";
@import "./variables.scss";
@import "./title.scss";
@import "./cards.scss";
@import "./navBar.scss";
@import "./hero.scss";
@import "./about.scss";
@import "./footer.scss";
@import "./contact.scss";
@import "./service.scss";

html {
  font-size: 100%;
  box-sizing: border-box;
	text-align: center;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

a{
	text-decoration: none;
	color: $font;
	font-weight: 700;
}

.page{
	margin-top:55px;
}
