.about {
  &__image {
    width: 500px;
    height: auto;
    @include mobile {
      width: 80%;
    }
  }
  &__container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    padding-bottom: 50px;
    border-bottom: 3px solid $primary;
    @include tablet {
      flex-direction: column;
    }
  }
  &__description {
    width: 500px;
    padding: 50px;
    text-align: justify;
    @include mobile {
      width: 100%;
    }
  }
}

.mision {
  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    margin: 50px auto;
  }
  &__card {
    width: 300px;
    margin: 40px;
    padding: 0px 25px;
    padding-bottom: 20px;
    border: 5px solid $primary;
    background-color: #444;
    color: white;
    &__description {
      text-align: center;
      text-align: left;
      &__title {
        font-weight: 700;
      }
    }
    &:hover .mision__text {
      color: $primary;
      transform: scale(1.05);
    }
    &:hover svg {
      transform: scale(1.3);
    }
  }
  &__card svg {
    font-size: 70px;
    color: $primary;
    transition: all ease-in-out 300ms;
  }
  &__icon {
    padding-top: 20px;
  }
  &__text {
    transition: all ease-in-out 300ms;
  }
}
