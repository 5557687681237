.form{
	padding-top:10px;	
	padding-bottom: 50px;
	&__button {
		background-color: black !important;
		color: $primary !important;
	}
	&__text{
		color: $primary;
	}
}
