.card{
	border: 3px solid  $primary;
	border-radius:10px;
	// padding: 20px;
	// background-image: url("../assets/backgrounds/card-background.png");
	background-color: $secondary;
	min-height:250px;
	transition: all ease-in-out 300ms;
	&:hover{
		box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
		background-color: $primary;
		border: 3px solid  $secondary;
	}
	&:hover &__title{
		color:$secondary;
		border-bottom: 3px solid $secondary;
	}
	&:hover &__description {
		color: $secondary;
	}
	&__title{
		// font-size: 25px;
		padding:10px 0;
		display: block;
		border-bottom: 3px solid $primary;
		color: $primary;
	}
	&__description{
		padding: 0 20px;
	}
	&__container{
		display: grid;
		grid-template-columns: repeat(2, 450px);
		justify-content: center;
		align-items: center;
		gap: 20px;
		padding: 50px 0;
		color: white;
		@include tablet{
			grid-template-columns: repeat(1, 500px);
		}
		@include mobile{
			grid-template-columns: repeat(1, 300px);
		}
	}
}