.service {
  &__container {
    display: flex;
    flex-direction: column;
    background-image: url(../assets/images/mantAscen.jpg);
    justify-content: space-around;
    align-items: center;
    height: 500px;
    width: 55%;
    margin: auto;
    margin-bottom: 50px;
    background-size: cover;
    background-position: top;
    background-repeat: no-repeat;
    object-fit: cover;
  }
  &__description {
    width: 100%;
    text-align: center;
  }
  &__service {
    color: white;
    background-color: #444;
    display: block;
    font-size: 25px;
    font-weight: bold;
    padding: 20px 0;
    padding: 50px;
    @include tablet {
      font-size: 22px;
    }
    @include mobile {
      font-size: 18px;
    }
  }
  &__card {
    display: grid;
    grid-template-columns: repeat(2, 250px);
    max-width: 60px;
    height: 300px;
    // &:hover &__text {
    //     background-color: $primary;
    //     color: $secondary;
    //     font-size: 18px;
    // }
    // &:hover &__title {
    //     color: $secondary;
    // }
    @include tablet {
      width: 250px;
    }
    @include mobile {
      grid-template-columns: repeat(1, 300px);
      flex-direction: column;
      height: auto;
      width: 100%;
    }
    &__title {
      text-align: left;
      text-overflow: ellipsis;
    }
    &__image {
      width: 100%;
      height: 300px;
      object-fit: cover;
      overflow: hidden;
      @include mobile {
        height: 250px;
      }
      &__container {
        width: 100%;
        height: 300px;
        @include mobile {
          width: 300px;
          height: 250px;
        }
      }
    }
    &__container {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(2, 500px);
      gap: 40px;
      justify-content: center;
      align-items: center;
      padding: 60px 0;
      margin: auto;

      @include tablet {
        grid-template-columns: repeat(1, 500px);
      }
      @include mobile {
        grid-template-columns: repeat(1, 300px);
      }
    }
    &__description {
      width: 100%;
    }
    &__text {
      padding: 10px;
      height: 300px;
      background-color: #444;
      color: white;
      transition: all ease 300ms;
      position: relative;
      @include mobile {
        width: 300px;
        height: auto;
      }
    }
    &__title {
      color: $primary;
      transition: all ease 300ms;
      @include mobile {
        margin-bottom: 61px;
        text-align: center;
        margin-top: 5px;
      }
    }

    &__button {
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      padding: 20px 0;
      background-color: black;
      transition: all ease-in-out 300ms;
      &:hover {
        background-color: $primary;
      }
      &:hover &__text {
        color: black;
      }
      &__text {
        color: white;
      }
    }
  }
}

.service-card {
  width: 300px;
  height: 300px;
  position: relative;
  margin: 20px;
  &-image {
    background-size: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  &-title {
    color: $primary;
    padding: 15px;
  }
  &-container {
    max-width: 1300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: auto;
  }
}
/* The flip card container - set the width and height to whatever you want. We have added the border property to demonstrate that the flip itself goes out of the box on hover (remove perspective if you don't want the 3D effect */
.flip-card {
  background-color: transparent;
  width: 300px;
  height: 300px;
  perspective: 1000px; /* Remove this if you don't want the 3D effect */
}

/* This container is needed to position the front and back side */
.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.8s;
  transform-style: preserve-3d;
}

/* Do an horizontal flip when you move the mouse over the flip box container */
.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

/* Position the front and back side */
.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden; /* Safari */
  backface-visibility: hidden;
}

/* Style the front side (fallback if image is missing) */
.flip-card-front {
  color: black;
}

/* Style the back side */
.flip-card-back {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 20px;
  background-color: rgba(255, 204, 0, 0.9);
  color: black;
  transform: rotateY(180deg);
  &-link {
    padding: 15px;
    border: 3px solid black;
    border-radius: 15px;
    background-color:$primary;
    margin: 0;
  }
}
.service{
	&__container{
		display: flex;
        flex-direction: column;
        background-image: url(../assets/images/mantAscen.jpg);
		justify-content: space-around;
		align-items:center;
        height: 500px;
        width: 55%;
        margin: auto;
        margin-bottom: 50px;
        background-size:cover;
        background-position: top;	
        background-repeat: no-repeat;
        object-fit: cover;
    }
	&__description{
		width: 100%;
		text-align: center;
	}
    &__service{
        color: white;
        background-color: #444;
        display: block;
        font-size: 25px;
        font-weight: bold;
        padding: 20px 0;
        padding: 50px;
        @include tablet {
            font-size: 22px;
        }
        @include mobile {
            font-size: 18px;
        }

    }
    &__card{
        display: grid;
        grid-template-columns: repeat(2,250px);
        max-width: 60px;
        height: 300px;
        // &:hover &__text {
        //     background-color: $primary;
        //     color: $secondary;
        //     font-size: 18px;
        // }
        // &:hover &__title {
        //     color: $secondary;
        // }
        @include tablet{
            width: 250px;
        }
        @include mobile{
            grid-template-columns: repeat(1,300px);
            flex-direction: column;
            height: auto;
            width: 100%;
        }
        &__title{
            text-align: left;
            text-overflow:ellipsis;
        }
        &__image{
            width: 100%;
            height: 300px;
            object-fit: cover;
            overflow: hidden;
            @include mobile{
                height: 250px;
            }
            &__container{
                width: 100%;
                height: 300px;
                @include mobile{
                    width: 300px;
                    height: 250px
                }
            }
        }
        &__container{
            width: 100%;
            display: grid;            
            grid-template-columns: repeat(2,500px);
            gap: 40px;
            justify-content: center;
            align-items: center;
            padding: 60px 0;
            margin: auto;

            @include tablet{
                grid-template-columns: repeat(1,500px);
            }
            @include mobile{
                grid-template-columns: repeat(1,300px);
            }
        }
        &__description{
            width: 100%;
        }
        &__text{
            padding: 10px;
            height:300px;
            background-color: #444;
            color: white;
            transition: all ease  300ms;
            position: relative;
            @include mobile{
                width: 300px;
                height:auto;
            }
        }
        &__title{
            color: $primary;
            transition: all ease  300ms;
            @include mobile{
                margin-bottom: 61px;
                text-align: center;
                margin-top: 5px;
            }
        }

        &__button{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 20px 0;
            background-color: black;
            transition: all ease-in-out 300ms;
            &:hover { background-color: $primary;}
            &:hover &__text{ color: black;}
            &__text{
                color: white;
            }
        }
    }
}
