@mixin desktop {
  @media only screen and (min-width: 992px) {
    @content;
  }
}
@mixin tablet {
  @media only screen and (max-width: 991px) {
    @content;
  }
}
@mixin mobile {
  @media only screen and (max-width: 767px) {
    @content;
  }
}