.hero{
	width: 100%;
	height: 450px;
	background-repeat: no-repeat;
	background-size: cover;
	backdrop-filter: opacity(80%);
	display: flex;
	justify-content: center;
	align-items: center;
	&__filter{
		width: 100%;
		height: 100%;
		background-color: rgba(0,0,0,0.5);
		background-size: cover;
		position: absolute;
		z-index: 100;
	}
	&__title{
		color: white;
		z-index: 101;
	}
}

.carousel__image{
	width: 100%;
	height: 620px;
	object-fit: cover;
}

.paper{
	padding-bottom: 20px;
}