.App {
  text-align: center;
  position: relative;
  overflow-x: hidden;
  width: 100vw;
  height: 100vh;
}

/* TODO: EFECTOS PARA LOGO */
.container{   
  width: 725px;
  height: 200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 70px;
}

.div-img{
  display: block;
  margin-left: 0;
  margin-right: auto;
}

.hidden{
  overflow: hidden;
}

.img{
  display:block;
  /* margin-left: 80px; */
  margin-left: 0px;
  margin-right: auto;
  width: 200px;
  height: 200px;
  -webkit-transition: all 500ms ease-in-out;
  -moz-transition: all 500ms ease-in-out;
  -o-transition: all 500ms ease-in-out;
  transition: all 500ms ease-in-out;
  animation: 3s infinite alternate slidein;
}      

.text{
  position:absolute;
  z-index: 5;
  display:block;
  height: 200px;
  top:0;
  right:0;
  padding-left:5px;
  opacity: 0;
}

.text img{
  width: auto;
  height: 100%;
  top:0;
}

.div-img:hover .img{
  /* transform:translate(-90px,0px);
  -ms-transform:translate(-90px,0px); 
  -moz-transform:translate(-90px,0px); 
  -webkit-transform:translate(-90px,0px); 
  -o-transform:translate(-90px,0px);  */
  
  transform:translate(90px,0px);
  -ms-transform:translate(90px,0px); 
  -moz-transform:translate(90px,0px); 
  -webkit-transform:translate(90px,0px); 
  -o-transform:translate(90px,0px);     
} 

.div-img .text{
  opacity: 1;
}

.player{   
  width: 550px;
  height: auto;
  max-height: 550px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 120px;
}

.footer{ 
  width: 100%;
  height: auto;
  margin-top: 120px;
  margin-bottom: 20px;
  display:flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.address, .phone, .email{
  display: flex;
  align-items: center;
  width: 33%;
  padding-top: 10px;
}

.address img, .phone  img, .email img{
  margin-right: 10px;
}

.address p, .phone  p, .email p{
  margin: 0;
}

.numbers{
  display: flex;
  flex-direction: column;
}


@media screen and (max-width: 780px) {
  .container{   
    width: 400px;
    height: 120px;
    
  }
  
  .img{
    width: 120px;
    height: 120px;
  }      
  
  .text{
    height: 120px;
  }
  
  .div-img:hover .img{    
    transform:translate(40px,0px);
    -ms-transform:translate(40px,0px); 
    -moz-transform:translate(40px,0px); 
    -webkit-transform:translate(40px,0px); 
    -o-transform:translate(40px,0px);     
  } 

  .player{   
    width: 70%;
    height: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;    
    left: 0;
    right: 0;
    top: 120px;
  }

  .footer{ 
    width: 100%;
    height: auto;
    margin-top: 120px;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .phone, .email{
    display: flex;
    align-items: center;
    width: 48%;
    justify-content: center;
  }

  .address{
    display: flex;
    align-items: center;
    width: 60%;
  }

  .address p, .phone  p, .email p{
    font-size: 13px;
  }
  
}

@media screen and (max-width: 450px) {
  .container{   
    width: 300px;
    height: 80px;
    
  }
  
  .img{
    width: 80px;
    height: 80px;
  }      
  
  .text{
    height: 80px;
  }
  
  .div-img:hover .img{    
    transform:translate(40px,0px);
    -ms-transform:translate(40px,0px); 
    -moz-transform:translate(40px,0px); 
    -webkit-transform:translate(40px,0px); 
    -o-transform:translate(40px,0px);     
  } 
  

  /* -------------------------------- */
  .player{   
    width: 90%;
    height: auto;
    position: relative;
    margin-left: auto;
    margin-right: auto;    
    left: 0;
    right: 0;
    top: 120px;
  }

  .footer{ 
    width: 100%;
    height: auto;
    margin-top: 120px;
    margin-bottom: 20px;
    display:flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  .phone, .email{
    display: flex;
    align-items: center;
    width: 48%;
  }

  .phone, .email, .address{
    display: flex;
    align-items: center;
    width: 99%;
    justify-content: flex-start;
  }

  .address p, .phone  p, .email p{
    font-size: 13px;
  }
  
}